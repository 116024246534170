import { useDevice } from '@utils/responsiveness/useDevice'
import { IConfigurableFeature } from '../ConfigurableFeatures/features.types'
import { ContentfulNextJsImage } from '@components/common/ContentfulImage'

interface IConfigurableListMarqueue {
  features: IConfigurableFeature[]
}

const DESKTOP_ITEM_WIDTH = 150
const DESKTOP_ITEM_HEIGHT = 100
const MOBILE_ITEM_WIDTH = 100
const MOBILE_ITEM_HEIGHT = 65
const ANIMATION_DELAY_PER_ITEM_IN_SECONDS = 4

export function ConfigurableListMarqueue({
  features,
}: IConfigurableListMarqueue) {
  const { isMobile } = useDevice()

  const totalItems = features?.length || 0

  const itemWidth = isMobile ? MOBILE_ITEM_WIDTH : DESKTOP_ITEM_WIDTH
  const itemHeight = isMobile ? MOBILE_ITEM_HEIGHT : DESKTOP_ITEM_HEIGHT

  if (!totalItems) {
    return null
  }

  // We add half of the width when calculating offset to make sure
  // that distance between elements will be at least equal to element's width
  const itemOffset = totalItems * (itemWidth + itemWidth / 2)

  // We multiplying delay by -1 to start some way through the animation and avoid empty marquee
  const getItemDelay = (itemIndex) => {
    return (
      ANIMATION_DELAY_PER_ITEM_IN_SECONDS * (totalItems - itemIndex) * -1 || 0
    )
  }

  return (
    <div className="flex w-full items-center relative overflow-hidden opaque-corners h-24 sm:h-40">
      {features.map((x, index) => {
        return (
          <ContentfulNextJsImage
            src={x?.image?.imageUrl}
            alt={x?.image?.imageTitle}
            width={itemWidth}
            height={itemHeight}
            quality={90}
            format="jpg"
            className="animate-[scrollLeft]"
            key={x?.image?.imageUrl + index}
            style={{
              position: 'absolute',
              width: `${itemWidth}px`,
              height: `${itemHeight}px`,
              left: `${itemOffset}px`,
              objectFit: 'contain',
              animation: `scrollLeft linear 
                ${ANIMATION_DELAY_PER_ITEM_IN_SECONDS * totalItems}s
                ${getItemDelay(index)}s
                infinite`,
            }}
          />
        )
      })}
    </div>
  )
}
